import React from "react";
import Breadcrumbs from "../components/global/breadcrumbs";
import Footer from "../components/global/footer";
import Navigation from "../components/global/navigation";
import SEO from "../components/global/seo";

// CSS
import "../styles.css"

const ContactUs = () => {
   return (
      <React.Fragment>
         <SEO
            title="Get in touch"
            slug="contact"
            metaDescription="Get in touch with us about your current policy or sign up to one of our flexible plans."
            image=""
            titleTemplate="%s - Appliance Sure"
         />

         <Navigation/>

         <div className="content-hero-container">
            <div className="content-hero-content-container">
               <h1>Get in touch</h1>
               
               <p>Contact one of our friendly experts who will be happy to help you with your new or existing policy with us. Look forward to speaking with you!</p>
            </div>
         </div>

         <div className="standard-container">
            <div className="content-container narrow-width">
               <Breadcrumbs
                  location="contact"
               />

               <h2>Choose a contact method</h2>

               <div className="form-2col" style={{fontSize: 17, marginTop: -20}}>
                  <span>
                     <h3>Customer service</h3>

                     <p><i class="fa-solid fa-phone" style={{color: '#03989e'}}></i> &nbsp;<a className="plain-text-button" href="tel:+442039536001">0203 953 6001</a></p>
                     <p><i class="fa-solid fa-envelope" style={{color: '#03989e'}}></i> &nbsp;<a className="plain-text-button" href="mailto:hello@appliancesure.com">hello@appliancesure.com</a></p>
                     <p><i class="fa-solid fa-comments" style={{color: '#03989e'}}></i> &nbsp;<button className="plain-text-button" onClick={() => window.$zoho.salesiq.chat.start()}>Live chat</button></p>

                     <br/>

                     <h3>New customers</h3>

                     <p><i class="fa-solid fa-phone" style={{color: '#03989e'}}></i> &nbsp;<a className="plain-text-button" href="tel:+442039661518">0203 966 1518</a></p>

                     <br/>
                     
                     <h3>Data privacy</h3>

                     <p><i class="fa-solid fa-envelope" style={{color: '#03989e'}}></i> &nbsp;<a className="plain-text-button" href="mailto:privacy@appliancesure.com">privacy@appliancesure.com</a></p>
                  </span>

                  <span>
                     <h3>Head office</h3>

                     <p>2nd Floor, Melrose House<br/>42 Dingwall Road<br/>Croydon<br/>Surrey<br/>CR0&nbsp;2NE</p>
                     
                     <br/>
                     
                     <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.362786669601!2d-0.09763198424405775!3d51.378010079614015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487607329b228701%3A0x18ebc24259eabefe!2sMelrose%20House!5e0!3m2!1sen!2suk!4v1666105511692!5m2!1sen!2suk" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </span>
               </div>
            </div>
         </div>

         

         <Footer/>
      </React.Fragment>
   )
}

export default ContactUs